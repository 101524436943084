<template>
  <div>
    <div class="header">
        <div class="container">
          <div class="row">
            <a class="col-2" href="">
              <!-- wenn kein Icon, img entfernen -->
            </a>
            <div class="col-8 col-center">
              <p class="pbolduppercase fontcolor-white">Information</p>
            </div>
            <div class="col-2 col-right">
              <!-- wenn kein Icon, img entfernen -->
            </div>
          </div>
        </div>
    </div>

    <div class="margintop140">
      <div @click="informationzuinformationnachdertx()">
        <informationsteaser teasertitle="Nach der TX" teaseruntertitle="Fragen und Antworten"><img src="../assets/icons/question_white2.svg"></informationsteaser>
      </div>

      <div @click="informationzuinformationmedikation()">
        <informationsteaser teasertitle="Medikation" teaseruntertitle="Medikamentenkategorien"><img src="../assets/icons/medikation_white.svg"></informationsteaser>
      </div>

      <div @click="informationzuinformationkomplikationen()">
        <informationsteaser teasertitle="Komplikationen" teaseruntertitle="Risikofaktoren"><img src="../assets/icons/risiken_white.svg"></informationsteaser>
      </div>

      <div @click="informationzuinformationvorsorge()">
        <informationsteaser teasertitle="Vorsorge" teaseruntertitle="Richtige Prävention"><img src="../assets/icons/vorsorge_white.svg"></informationsteaser>
      </div>

      <div @click="informationzuinformationernaehrung()">
        <informationsteaser teasertitle="Ernährung" teaseruntertitle="Hilfreiche Tipps"><img src="../assets/icons/ernaehrung_white.svg"></informationsteaser>
      </div>

      <div @click="informationzuinformationwerteverstehen()">
        <informationsteaser teasertitle="Werte verstehen" teaseruntertitle="Vital- und Laborwerte"><img src="../assets/icons/werteerfassung.svg"></informationsteaser>
      </div>

    </div>

    <div class="margintop20 container paddingbottom110">
      <p class="psmallregular fontcolor-white opacity40">
        Quellen: <br><br>
        Transplantationszentrum Freiburg (2018): Das Freiburger Infobüchle, Patienteninformation.<br><br>
        Nierenzentrum Heidelberg (2014): Informationsborschüre für Nierentransplantierte.<br><br>
      </p>
    </div>
    
    <foot-navigation></foot-navigation>
  </div>
</template>

<style scoped>
</style>

<script>
  import FootNavigationVue from "../components/FootNavigation.vue";
  import InformationsTeaserVue from "../components/InformationsTeaser.vue";
  import router from '../router';

  export default {
    name: 'information',
    components: {
      'foot-navigation' : FootNavigationVue,
      'informationsteaser' : InformationsTeaserVue,
    },
    methods: {
      informationzuinformationnachdertx() {
        router.push({ path: '/informationnachdertx' })
      },
      informationzuinformationmedikation() {
        router.push({ path: '/informationmedikation' })
      },
      informationzuinformationkomplikationen() {
        router.push({ path: '/informationkomplikationen' })
      },
      informationzuinformationvorsorge() {
        router.push({ path: '/informationvorsorge' })
      },
      informationzuinformationernaehrung() {
        router.push({ path: '/informationernaehrung' })
      },
      informationzuinformationwerteverstehen() {
        router.push({ path: '/informationwerteverstehen' })
      },
    }
  };
</script>
