<template>
  <div>
    <div class="aufgabe">
      <div class="container">
        <div class="row">
          <div class="col-2 center">
            <slot></slot>
            <!--<img :src="path"/>-->
            <!--<img :src="require(`@/assets/icons/${filename}`)">-->
            <!--<img :src="getImage(imageURL)">-->
            <!--<img :src="props.myImagePath">-->
            <!---<img v-bind:src=imageSource />-->
          </div>
          <div class="col-8 col-center left">
            <p class="pbold fontcolor-white">{{ teasertitle }}</p>
            <p class="pregular fontcolor-white opacity40">{{ teaseruntertitle }}</p>
          </div>
          <div class="col-2 col-right center">
            <img src="../assets/icons/arrow_right.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  props: ['teasertitle', 'teaseruntertitle',]
}
</script>